import * as React from 'react'
import { NextPage } from 'next'
import Home from '@core/components/Home'
import HeadTags from '@core/components/shared/HeadTags'
import config from '@core/config/config'
import { useTranslation } from '@core/i18n/i18n'
import { IPageLayout } from '@core/prismic/types'
import { formatPageTitle } from '@core/utils/title'
import withErrorBoundary from '@core/utils/withErrorBoundary'

interface InitialProps {
  pageLayout?: IPageLayout
}

export const LandingPage: NextPage<InitialProps> = ({ pageLayout }) => {
  const { t } = useTranslation()
  return (
    <>
      <HeadTags
        title={
          pageLayout?.page_title ??
          formatPageTitle(t`seo.defaultTitles.landing`)
        }
        description={pageLayout?.page_description ?? t`seo.description`}
      />
      <Home pageLayout={pageLayout} />
    </>
  )
}

LandingPage.getInitialProps = async ({ services }) => {
  const pageLayout = await services.prismicApi.getPageLayout(
    config.prismic.landingPageSlug
  )

  return {
    pageLayout,
  }
}

export default withErrorBoundary(LandingPage)
